import React, { Children } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { FiChevronDown } from 'react-icons/fi';

import accordionStyle from "./accordionStyle.js";

const useStyles = makeStyles(() => ({
    ...accordionStyle
}));

export default function ServiceAccordion(props) {
    const classes = useStyles();
    const { title, description, children, expanded, toggleCollapse, serviceBusinessId, businessSettings } = props;
    return (
        <Accordion
            className={classes.accordionContainer}
            TransitionProps={{ unmountOnExit: true }}
            expanded={expanded}
        >
            <AccordionSummary
                className={classes.accordionHeader}
                expandIcon={
                    <FiChevronDown 
                        color={businessSettings?.service_item_title ? businessSettings.service_item_title : 'rgba(0, 0, 0, 0.54)'}
                        size={20}
                    />
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
                onClick={() => toggleCollapse(serviceBusinessId)}
                style={businessSettings && businessSettings.service_item_background ?
                    { backgroundColor: businessSettings.service_item_background }
                : null}
            >
                <div>
                    <div
                        className={classes.accordionTitle}
                        style={businessSettings?.service_item_title ?
                            { color: businessSettings.service_item_title }
                        : null}
                    >
                        {title}
                    </div>
                    <div 
                        className={classes.accordionDescription}
                        style={businessSettings && businessSettings.service_item_description ?
                            { color: businessSettings.service_item_description }
                        : null}
                    >
                        {description}
                    </div>
                </div>
            </AccordionSummary>
            <AccordionDetails
                className={classes.accordionContent}
                style={businessSettings && businessSettings.service_tier_background ?
                    { backgroundColor: businessSettings.service_tier_background }
                : null}
            >
                {children}
            </AccordionDetails>
        </Accordion>
    );
}